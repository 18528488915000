const lazyLoadThisScript = async (place, path, pos = 'last', time = 5000) => {
		return new Promise(resolve => {
				setTimeout(async () => {
						let injectedScript = path.map(async ({async = 0, defer = 0, src = null}) => {
								return await createScriptTag(place, src, pos, async, defer);
						});
						resolve(injectedScript);
				}, time);
		});
}

const lazyLoadCss = async (path, pos = 'last', time = 5000) => {
		/*	The function responsible for lazy loading our css files	*/
		return new Promise(resolve => {
				setTimeout(async () => {
						let injectedLinks = [];
						switch (typeof path) { /*	This refers to the type of the path. It can be string or object	*/
								case 'object':
										injectedLinks = path.map(async stylePath => {
												return await createLinkMetaTag(stylePath, pos);
										});
										break;
								case 'string': /*	If the path is a single path	*/
										let injectedLink = await createLinkMetaTag(path, pos);
										injectedLinks.push(injectedLink);
										break;
						}
						resolve(injectedLinks);
				}, time);
		});
}

const createScriptTag = async (place, src, pos, async, defer) => {
		let scriptTag = document.createElement("script");
		scriptTag.setAttribute("src", src);
		if (!!async) scriptTag.setAttribute("async", "async");
		if (!!defer) scriptTag.setAttribute("defer", "defer");

		if (place === "head") {
				if (pos === 'last') document.head.appendChild(scriptTag);
				else document.head.insertBefore(scriptTag, document.head.firstElementChild);
		} else {
				if (pos === 'last') document.body.appendChild(scriptTag);
				else document.body.insertBefore(scriptTag, document.body.firstElementChild);
		}
		return scriptTag;
}

const createLinkMetaTag = async (path, pos) => {
		let link = document.createElement('link'); /*	Create the link element	*/
		link.href = path; /*	Assign the value of path to the link's href	*/
		link.rel = 'stylesheet'; /*	Assign 'stylesheet' to the link	*/
		link.type = 'text/css';
		if (pos === 'last') document.head.appendChild(link);/*	Appends the link to the head of the document.	*/
		else document.head.insertBefore(link, document.head.firstElementChild);/*	inject the link to the head of the document.	*/
		return link;
}

const loadThisUILater = async (content, type, elm) => {
		switch (type) {
				case 'append':
						$(content).appendTo(elm);
						break;
				case 'after':
						$(content).insertAfter(elm);
						break;
				case 'before':
						$(content).insertBefore(elm);
						break;
				default:
						alert('cannot render UI element');
						break;
		}
}
